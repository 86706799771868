@import url('https://fonts.googleapis.com/css?family=Arimo|Noto+Sans+KR|Nunito|Questrial|Rubik|Titillium+Web&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;400&family=Roboto:wght@100&display=swap');

.App {
  padding: 0;
  margin: 0;
  text-align: center;
  background-color: '#F8F8F8';

}

.main-header {
  width: 100%;
  height: 300px;

}

/* .navbar {
  background-color: rgb(248, 248, 248);

} */

.titulo-page h1 {
  padding: 10px;
  font-family: 'Montserrat', sans-serif;
  font-size: 50px;
  color: rgb(7, 7, 7);
  align-items: center;
  text-align: right;

}


.sub-title h3 {
  font-family: 'SpartanMB-Thin.oft';
  font-size: 30px;
  padding: 5px;
  text-align: left;



}

.new-title {
  font-family: 'SpartanMB-Thin.oft';
  padding: 10px;
}

/* .Button button {

  color: rgb(7, 7, 7);
  background-color: rgb(248, 248, 248);
  font-family: "SpartanMB-Thin.oft";


} */

.Button .btn {
  -webkit-transition-duration: 0.4s;
  /* Safari */
  transition-duration: 0.4s;
}

.Button .btn :hover {
  background-color: #4CAF50;
  /* Green */
  color: white;
}

.navigator {
  font-family: "SpartanMB-Thin.oft";
  font-size: 20px;
  background-color: rgb(248, 248, 248);

}

.Work h2 {
  font-family: 'Montserrat', sans-serif;
  color: rgb(105, 105, 105);

}

.Work h3 {
  font-family: "SpartanMB-Regular.oft";
}

.Work h4 {
  font-family: "SpartanMB-Thin.oft";
  font-size: 20px;
}

.Work p {
  font-family: "SpartanMB-Thin.oft";
  font-size: 12px;
}

.Work {
  padding: 10px;
  text-align: center;

}

.Work {
  background-color: rgb(248, 248, 248);


}

.Work .works {
  padding: 10px;
}

.Work .work-title {
  font-family: "Lato_400Regular",
}

.perfil {
  margin-left: 700px;


}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}



.ficha {

  background-image: radial-gradient(73% 147%, #eeebeb 59%, #f2eeed 100%), radial-gradient(91% 146%, rgba(255, 255, 255, 0.50) 47%, rgba(0, 0, 0, 0.50) 100%);
  background-blend-mode: screen;
  opacity: 0.90;


}

.text-ficha {
  font-family: "SpartanMB-Thin.oft";
  color: rgb(8, 8, 8);
  font-size: 15px;
  margin-top: 10px;
  padding: 10px;
  text-align: justify;
}

.text-ficha spam {
  font-family: 'SpartanMB-Regular.oft';
  color: rgb(0, 0, 0);
}

.title-img {
  font-family: "SpartanMB-Thin.oft";
  text-align: left;
}

@font-face {
  font-family: "Scriptyca.ttf";
  src: url("./fonts/Scriptyca.ttf");
}

@font-face {
  font-family: "SpartanMB-Regular.oft";
  src: url("./fonts/SpartanMB/SpartanMB-Regular.otf");
}

@font-face {
  font-family: "SpartanMB-Thin.oft";
  src: url("./fonts/SpartanMB/SpartanMB-Thin.otf");
}

@font-face {
  font-family: 'Nunito', sans-serif;
  font-family: 'Titillium Web', sans-serif;
  font-family: 'Rubik', sans-serif;
  font-family: 'Noto Sans KR', sans-serif;
  font-family: 'Arimo', sans-serif;
  font-family: 'Questrial', sans-serif;
  font-family: 'Montserrat', sans-serif;
  font-family: 'Roboto', sans-serif;



  src: url("https://fonts.google.com/?selection.family=Arimo|Noto+Sans+KR|Nunito|Questrial|Rubik|Titillium+Web"), ('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;400&family=Roboto:wght@100&display=swap');
}

/*Border aboutme*/
.FancyBorder {
  padding: 10px 10px;
  border: 2px solid;
}

.FancyBorder-blue {
  border-color: black;
}