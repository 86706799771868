.Card {

    background-color: rgb(248, 248, 248);
    border: node;
}

.Card .container {
    background-color: rgb(248, 248, 248);
    font-family: 'Questrial', sans-serif;
}

.Card .Title {
    background-color: rgb(255, 255, 255);
    font-family: 'Monserrat', sans-serif;

}


@font-face {
    font-family: 'Nunito', sans-serif;
    font-family: 'Titillium Web', sans-serif;
    font-family: 'Rubik', sans-serif;
    font-family: 'Noto Sans KR', sans-serif;
    font-family: 'Arimo', sans-serif;
    font-family: 'Questrial', sans-serif;
    font-family: 'Montserrat', sans-serif;
    font-family: 'Roboto', sans-serif;


    src: url("https://fonts.google.com/?selection.family=Arimo|Noto+Sans+KR|Nunito|Questrial|Rubik|Titillium+Web");
}